exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-page-detail-ads-js": () => import("./../../../src/templates/pageDetailAds.js" /* webpackChunkName: "component---src-templates-page-detail-ads-js" */),
  "component---src-templates-page-detail-artists-js": () => import("./../../../src/templates/pageDetailArtists.js" /* webpackChunkName: "component---src-templates-page-detail-artists-js" */),
  "component---src-templates-page-detail-movies-js": () => import("./../../../src/templates/pageDetailMovies.js" /* webpackChunkName: "component---src-templates-page-detail-movies-js" */),
  "component---src-templates-page-product-js": () => import("./../../../src/templates/pageProduct.js" /* webpackChunkName: "component---src-templates-page-product-js" */)
}

